export const agreement_legal_term_description = `These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and Studybuddy2 LLC, concerning your access to and use of the Services. By accessing the Services, you agree that you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.

Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the "Last updated" date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.

The Services are intended for users who are at least 13 years of age. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor, you must have your parent or guardian read and agree to these Legal Terms prior to using the Services.

Studybuddy is designed to assist you in studying and understanding educational material. It is not intended for cheating or to be used on any exams, tests, or graded assignments. The user takes full responsibility for the appropriate use of this tool. Please use Studybuddy ethically and responsibly.

Before using the Studybuddy Extension with your Learning Management System (LMS), please be aware that its use may be governed by specific policies and guidelines established by your educational institution or LMS provider. Make sure to comply with these regulations to avoid any potential issues.`;

export const contents = [
    'OUR SERVICES',
    'INTELLECTUAL PROPERTY RIGHTS',
    'USER REPRESENTATIONS',
    'USER REGISTRATION',
    'PURCHASES AND PAYMENTS',
    'SUBSCRIPTIONS',
    'PROHIBITED ACTIVITIES',
    'CONTRIBUTION LICENSE',
    'THIRD-PARTY WEBSITES AND CONTENT',
    'SERVICES MANAGEMENT',
    'PRIVACY POLICY',
    'TERM AND TERMINATION',
    'MODIFICATIONS AND INTERRUPTIONS',
    'GOVERNING LAW',
    'DISPUTE RESOLUTION',
    'CORRECTIONS',
    'DISCLAIMER',
    'LIMITATIONS OF LIABILITY',
    'INDEMNIFICATION',
    'ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES',
    'CALIFORNIA USERS AND RESIDENTS',
    'MISCELLANEOUS',
    'CHANGE TO TERMS',
    'CONTACT US',
    'MOBILE TERMS OF SERVICE',
];

export const term_1 = {
    title: '1. OUR SERVICES',
    description: `The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.

The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).`,
};

export const term_3 = {
    title: '3. USER REPRESENTATIONS',
    description: `By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal Terms; (4) you are not under the age of 13; (5) you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Services; (6) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise; (7) you will not use the Services for any illegal or unauthorized purpose; and (8) your use of the Services will not violate any applicable law or regulation.

If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).`,
};

export const term_4 = {
    title: '4. USER REGISTRATION',
    description: `You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.`,
};

export const term_8 = {
    title: '8. CONTRIBUTION LICENSE',
    description: `By submitting feedback, ideas, or suggestions ("Contributions"), you grant us a perpetual, worldwide, royalty-free, irrevocable, and sublicensable license to use, reproduce, modify, and distribute such Contributions for any purpose without compensation or acknowledgment.`,
};

export const term_9 = {
    title: '9. THIRD-PARTY WEBSITES AND CONTENT',
    description: `Our Services may include links to external websites ("Third-Party Websites") or provide access to articles, photographs, text, graphics, designs, music, sound, video, applications, software, and other content originating from third parties ("Third-Party Content"). Please be aware that we do not investigate, monitor, or verify the accuracy, appropriateness, or completeness of Third-Party Websites or Third-Party Content.

We are not responsible for any Third-Party Websites accessed through our Services or any Third-Party Content posted on, available through, or installed from our Services. This includes, but is not limited to, the content, accuracy, legality, opinions, reliability, privacy practices, or other policies associated with Third-Party Websites or Third-Party Content. The inclusion of links or access to Third-Party Websites or Third-Party Content does not imply endorsement or approval by us.

If you choose to leave our Services to visit Third-Party Websites or to use or install Third-Party Content, you do so at your own risk. Our Terms do not apply to Third-Party Websites or Third-Party Content, and you should review the applicable terms, policies, and privacy practices of any third party you interact with.

Any transactions or purchases made through Third-Party Websites are solely between you and the third party. We are not responsible for any issues arising from such transactions and disclaim all responsibility for the products or services offered on Third-Party Websites. You agree to hold us harmless from any harm or losses resulting from your interaction with Third-Party Websites or Third-Party Content.`,
};

export const term_12 = {
    title: '12. TERM AND TERMINATION',
    description: `These Terms and Conditions shall remain in effect while you use the Services provided by Studybuddy2 LLC. We reserve the right to, at our sole discretion and without prior notice or liability, deny access to and use of the Services to any individual for any reason or no reason at all. This includes, but is not limited to, instances of breach of any representation, warranty, or covenant contained in these Terms and Conditions, or violations of any applicable laws or regulations. We may terminate your use or participation in the Services or delete your account and any content or information you have posted at any time, without warning and at our sole discretion.

If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fictitious name, or the name of any third party, even if acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to pursue appropriate legal action, including civil, criminal, and injunctive relief.`,
};

export const term_13 = {
    title: '13. MODIFICATIONS AND INTERRUPTIONS',
    description: `Studybuddy2 LLC reserves the right to change, modify, or remove any aspect of the Services at our sole discretion and without prior notice. This includes any content, features, or functionalities of the Services. We are not obligated to update any information on our Services, and we shall not be liable to you or any third party for any modifications, price changes, suspensions, or discontinuations of the Services.

The availability of the Services is not guaranteed at all times. We may encounter issues related to hardware, software, or other factors, or may need to perform maintenance, which could result in interruptions, delays, or errors. Studybuddy2 LLC reserves the right to revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we shall not be liable for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuation. Nothing in these Terms and Conditions obligates us to maintain or support the Services or to provide any updates, corrections, or releases related to them.`,
};

export const term_14 = {
    title: '14. GOVERNING LAW',
    description: `These Terms and Conditions, and your use of the Services, are governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles. Any disputes arising out of or related to these Terms and Conditions or the Services shall be resolved in accordance with the laws of the State of Delaware.`,
};

export const term_15 = {
    title: '15. DISPUTE RESOLUTION',
    descriptions: [
        {
            title: `Informal Negotiations\n\n`,
            description: `To resolve any dispute, controversy, or claim related to these Terms and Conditions (each a "Dispute" and collectively, the "Disputes") brought by either you or us (individually, a "Party" and collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute informally for at least thirty (30) days before initiating formal proceedings. Informal negotiations will commence upon written notice from one Party to the other Party.`,
        },
        {
            title: 'Binding Arbitration\n\n',
            description: `If the Parties cannot resolve a Dispute through informal negotiations, the Dispute (except those specifically excluded below) will be resolved exclusively through binding arbitration. You understand that by agreeing to arbitration, you are waiving your right to a court trial and a jury trial. The arbitration will be conducted under the Commercial Arbitration Rules of the American Arbitration Association ("AAA") and, if applicable, the AAA's Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), which are available on the AAA website. The arbitration may be conducted in person, by phone, online, or through the submission of documents. The arbitrator will issue a written decision but is not required to provide a detailed explanation unless requested by either Party. The arbitrator must adhere to applicable law, and any award may be challenged on the grounds of legal error. Arbitration will take place in Delaware. The Parties may seek court intervention to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the arbitration award.`,
        },
        {
            title: 'Court Proceedings\n\n',
            description: `If for any reason a Dispute proceeds in court rather than through arbitration, it shall be filed in the state and federal courts located in Delaware. The Parties consent to the jurisdiction of these courts and waive any defenses related to personal jurisdiction or venue. The application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) is excluded from these Terms and Conditions.`,
        },
        {
            title: 'Restrictions\n\n',
            description: `Arbitration shall be limited to the individual Dispute between the Parties. To the fullest extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right to arbitration on a class-action basis or to utilize class action procedures; and (c) no Dispute may be brought in a representative capacity on behalf of the general public or other persons.`,
        },
        {
            title: 'Exceptions to Informal Negotiations and Arbitration\n\n',
            description: `The following Disputes are not subject to the informal negotiations or arbitration provisions: (a) any Disputes concerning the enforcement or protection of intellectual property rights; (b) any Dispute related to allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim seeking injunctive relief. If any part of this provision is deemed illegal or unenforceable, that part will not apply, and the remaining Disputes will be decided by a court of competent jurisdiction within the courts specified above, with the Parties agreeing to submit to that court’s jurisdiction.`,
        },
    ],
};

export const term_16 = {
    title: '16. CORRECTIONS',
    description: `The Studybuddy Services may contain typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Studybuddy Services at any time, without prior notice.`,
};
export const term_17 = {
    title: '17. DISCLAIMER',
    description: `THE STUDYBUDDY SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.`,
};
export const term_18 = {
    title: '18. LIMITATIONS OF LIABILITY',
    description: `IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $144.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.`,
};
export const term_19 = {
    title: '19. INDEMNIFICATION',
    description: `You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of: (1) use of the Studybuddy Services; (2) breach of these Legal Terms; (3) any breach of your representations and warranties set forth in these Legal Terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Studybuddy Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.`,
};
export const term_20 = {
    title: '20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES',
    description: `Visiting the Studybuddy Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.`,
};
export const term_21 = {
    title: '21. CALIFORNIA USERS AND RESIDENTS',
    description: `If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.`,
};
export const term_22 = {
    title: '22. MISCELLANEOUS',
    description: `These Legal Terms and any policies or operating rules posted by us on the Studybuddy Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.`,
};
export const term_25 = {
    title: '25. MOBILE TERMS OF SERVICE',
    description: `The Studybuddy mobile messaging service (the "Service") is operated by Studybuddy2 LLC ("Studybuddy," "we," or "us"). By using the Service, you agree to these terms and conditions ("Mobile Terms").`,
    descriptions: [
        {
            title: 'Service Modifications\n\n',
            description: `We may modify or discontinue the Service or any of its features without prior notice. To the extent permitted by applicable law, we may also update these Mobile Terms at any time. Your continued use of the Service after such changes are effective constitutes your acceptance of the updated terms.`,
        },
        {
            title: 'Consent to Receive Messages\n\n',
            description: `By consenting to Studybuddy’s SMS/text messaging service, you agree to receive recurring SMS/text messages from us to the mobile number you provided. This includes messages related to service updates, alerts, and promotional offers, even if your number is on any state or federal Do Not Call list. Messages may be sent using automated technology.`,
        },
        {
            title: 'Costs and Frequency\n\n',
            description: `While we do not charge for the Service, you are responsible for any charges or fees imposed by your wireless provider. Message frequency may vary, and standard message and data rates may apply. Please check with your mobile plan provider for details.`,
        },
        {
            title: 'Opt-Out\n\n',
            description: `You may opt-out of the Service at any time by texting "STOP" to +18885747091 or by clicking the unsubscribe link in any text message we send. You will receive a one-time confirmation text, and no further messages will be sent unless you initiate them. If you are subscribed to other Studybuddy mobile message programs, you must opt out separately from those programs.`,
        },
        {
            title: 'Support\n\n',
            description: `For assistance email support@studybuddy.gg.`,
        },
        {
            title: 'Changes and Responsibility\n\n',
            description: `We may change the short code or phone number used for the Service and will notify you of such changes. Messages sent to a previous number may not be received, and we are not responsible for honoring requests sent to outdated numbers. Wireless carriers are not liable for delayed or undelivered messages.`,
        },
        {
            title: 'Mobile Number Updates\n\n',
            description: `If you change your mobile number, you must re-register for the Service using your new number.`,
        },
        {
            title: 'Liability\n\n',
            description: `To the extent permitted by law, we are not liable for failed, delayed, or misdirected delivery of information through the Service, errors in such information, or any actions you may or may not take based on the information or Service.`,
        },
        {
            title: 'Privacy\n\n',
            description: `We respect your privacy. For details on how we collect and use your personal information, please refer to our Privacy Notice.`,
        },
    ],
};
