import React from 'react';
import { getDayAfterTwoDays } from '~utils';
import OfferIcon from '../../../assets/priceOffer-vector.svg';
export type PlanType = 'weekly' | 'three-month';

interface PriceDisplayProps {
    activeplan: PlanType;
    t: (key: string) => string;
    setIsPricingInView?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({ activeplan, t }) => {
    const pricingOfferEndDate = getDayAfterTwoDays();

    return (
        <div className="pricingTextInCard">
            <div
                id="writerToolPricePrice"
                className={`pricingValue ${activeplan === 'weekly' ? 'active' : ''}`}
            >
                {t('try_for')} $0.99
            </div>

            <div
                className={`writerToolPriceMonthly pricingValue ${activeplan === 'weekly' ? '' : 'active'} `}
            >
                <span className="writerToolOriginalPrice">$72.99</span>
                <span className="writerToolDiscountedPrice">$39.99</span>
            </div>

            <div id="writerToolPriceNoticeContainer">
                <img
                    className="writerToolOfferIcon"
                    src={OfferIcon}
                    alt="priceOffer"
                />
                <p id="writerToolPriceMobileNotice">
                    {t('offer_valid_until')} {pricingOfferEndDate}
                </p>
            </div>
        </div>
    );
};
export default PriceDisplay;
