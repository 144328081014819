import React, { FC, useState } from 'react';

import './ToolsCard.styles.scss';
import LearnMoreIcon from '../../../../assets/learn-more-black.svg';
import LearnMoreWhiteIcon from '../../../../assets/learn-more-white.svg';
import { useScreenSize } from '~hooks';
import { ToolsPopup } from '~components/Modals/ToolsPopup';
import TurboDescriptionDesktop from '~/assets/turbo-description-desktop.svg';
import TurboDescriptionMobile from '~/assets/turbo-description-mobile.svg';
import { ToolsCardType } from '../ToolsSection.types';

const ToolsCard: FC<ToolsCardType> = ({ ...props }) => {
    const { isMobile } = useScreenSize();

    const {
        TitleImageSrc,
        description,
        DescriptionTextImg,
        isTurbo,
        DescriptionImgSrc,
        descriptionImgStyle,
        variant,
    } = props;

    const [popupVariant, setPopupVariant] = useState('');
    const [isShowToolsPopUp, setIsShowToolsPopUp] = useState(false);

    const onLearnMoreClick = (popup_variant: any) => {
        setPopupVariant(popup_variant);
        setIsShowToolsPopUp(true);
    };

    const onCancelPopupCloseHandler = () => {
        setIsShowToolsPopUp(false);
        setPopupVariant('');
    };

    return (
        <>
            <ToolsPopup
                popupVariant={popupVariant}
                open={isShowToolsPopUp}
                onClose={onCancelPopupCloseHandler}
            />
            <div
                id={isTurbo ? 'bg-turbo-container' : 'bg-container'}
                className={'tools-card-item-container'}
            >
                <div className="tools-header">
                    <div
                        style={isMobile ? { width: '156px' } : { width: '55%' }}
                    >
                        <TitleImageSrc />
                    </div>

                    <button
                        className={
                            isTurbo ? 'learn-more-btn-turbo' : 'learn-more-btn'
                        }
                        onClick={() => onLearnMoreClick(variant)}
                    >
                        <img
                            src={isTurbo ? LearnMoreWhiteIcon : LearnMoreIcon}
                            alt="learn-more"
                        />
                    </button>
                </div>

                <div className="tools-text-container">
                    {(variant === 'app' || variant === 'turbo') &&
                        DescriptionTextImg && <DescriptionTextImg />}
                    {variant === 'writer' && (
                        <p
                            className={
                                isTurbo
                                    ? 'description-turbo-text-color'
                                    : 'description-text-color'
                            }
                        >
                            {description}
                        </p>
                    )}
                </div>

                {isTurbo && (
                    <div className="turbo-description-container">
                        <img
                            src={
                                isMobile
                                    ? TurboDescriptionMobile
                                    : TurboDescriptionDesktop
                            }
                            alt="turbo-description"
                        />
                    </div>
                )}

                <div style={descriptionImgStyle} className="description-image">
                    <DescriptionImgSrc />
                </div>
            </div>
        </>
    );
};

export default ToolsCard;
