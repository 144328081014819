import React, { FC, useState } from 'react';
import './Newsletter.styles.scss';
import { Modal } from 'react-bootstrap';
import { NewsletterPopupProps } from './Newsletter.types';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '~hooks';

import NewsletterHeaderImgDesktop from '~assets/newsletter-popup-heading-desktop.svg';
import NewsletterHeaderImgMobile from '~assets/newsletter-popup-heading-mobile.svg';

export const NewsletterPopup: FC<NewsletterPopupProps> = (props) => {
    const { show, handleClose, isBlackTheme } = props;

    const [isLoading, setIsLoading] = useState(false);
    const { isMobile } = useScreenSize();

    const onNewsletterSubmitHandler = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const name = data.get('name') as string;
        const email = data.get('email') as string;
        console.log('Name: >>>', name);
        console.log('Email: >>>', email);
        // let splitedName = splitName(name);

        /* (window as any).klaviyo.identify({
            $email: email,
            $first_name: splitedName.firstName,
            $last_name: splitedName.lastName,
        }); */

        // add to newsletter
        let response = await fetch(
            `https://api.maltertech.com/studybuddy/newsletter.php`,
            {
                method: 'POST',
                body: data,
            },
        ).then((response) => response.json());

        if (response.error) {
            alert(response.error);
            setIsLoading(false);
        } else {
            alert("You've successfully subscribed to our newsletter!");
            handleClose();
        }
        setIsLoading(false);
    };

    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            contentClassName="modalContent"
        >
            <div className={isBlackTheme ? "newsletter-popup white": "newsletter-popup"}>
                {/* <div className="newsletter-close-btn" onClick={handleClose}>
                    <img src="assets/images/close-icon.svg" alt="close" />
                </div> */}

                <form
                    className="newsletter-body"
                    onSubmit={onNewsletterSubmitHandler}
                >
                    {/* <h3>{t('before_you_go')}...</h3>
                    <p>{t('newsletter_body')}</p> */}

                    <img
                        src={
                            isMobile
                                ? NewsletterHeaderImgMobile
                                : NewsletterHeaderImgDesktop
                        }
                        alt="newsletter"
                    />
                    <div className="input-container">
                        <label>
                            Name<span>*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            required
                        />
                    </div>

                    <div className="input-container">
                        <label>
                            Email<span>*</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            required
                        />
                    </div>

                    <button
                        className={isBlackTheme ? "popup-cta-btn dark": "popup-cta-btn"}
                        type="submit"
                        disabled={isLoading}
                    >
                        {t('Claim Offer')}
                        {/* <img src="assets/images/arrow-right.svg" alt="arrow" /> */}
                    </button>
                    <button onClick={handleClose} className="popup-noThanks">
                        {t('Continue without offer')}
                    </button>

                    <p className="terms-text">
                        By sharing your email address, you agree to receive
                        marketing emails and consent to our{' '}
                        <a
                            href="/terms-conditions"
                            className="terms-anchor-text"
                        >
                            Terms & Conditions
                        </a>{' '}
                        {''}
                        and{' '}
                        <a href="/privacy-policy" className="terms-anchor-text">
                            Privacy Policy.
                        </a>
                    </p>
                </form>
            </div>
        </Modal>
    );
};
