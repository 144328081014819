import React, { FC, useEffect, useState } from 'react';
import { getDayAfterTwoDays } from '~utils';

// import { useFeatureIsOn } from '@growthbook/growthbook-react';

import './Pricing.styles.scss';
import OfferIcon from '../../../assets/priceOffer-vector.svg';
import { ReactComponent as CheckIconBlack } from '~assets/check-icon-black.svg';
import { useTranslation } from 'react-i18next';
import { useScreenSize, useInView } from '~hooks';

import TenMQues from '../../../assets/10M_ques_answered.svg';
import PlanToggle from './PlanToggle';
import PriceDisplay from './PriceDisplay';
import { icons } from '~constants';

type PlanType = 'weekly' | 'three-month';

type PricingProps = {
    handleCTA: () => void;
    setIsPricingInView?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Pricing: FC<PricingProps> = (props) => {
    const { handleCTA, setIsPricingInView } = props;
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();
    const [activeplan, setActivePlan] = useState<PlanType>('weekly');
    const { ref, inView } = useInView({ threshold: 0.1 });

    const onPricingClickHandler = () => {
        handleCTA();
    };

    useEffect(() => {
        setIsPricingInView?.(inView);
    }, [inView, setIsPricingInView, activeplan]);

    /* const onNewPricingClickHandler = () => {
        console.log('New Pricing Clicked');
        handleCTA('new_pricing');
    };

    const onPricingClickHandler = () => {
        console.log('Old Pricing Clicked');
        handleCTA('old_pricing');
    }; */

    const pricingOfferEndDate = getDayAfterTwoDays();

    return (
        <div id="pricing-section">
            <div id="price">
                <h1 id="priceTitle">StudyBuddy {t('pricing')}</h1>
                <h1 id="priceTitleMobile">
                    StudyBuddy <span>{t('pricing')}</span>
                </h1>
                {/* <PlanToggle
                    activeplan={activeplan}
                    setActivePlan={setActivePlan}
                /> */}

                {isMobile ? (
                    <div id="priceMobileContainer">
                        <div id="priceTag">
                            {activeplan === 'weekly' ? (
                                <p>
                                    {t('72 hour')}{' '}
                                    <span>{'Unlimited Access'}</span>
                                </p>
                            ) : (
                                <p>
                                    {t('Save')} <span>{'$33'}</span> with{' '}
                                    <span>{'SEMESTER PLAN'}</span>
                                </p>
                            )}
                        </div>
                        <div
                        // style={
                        //     activeplan === 'three-month'
                        //         ? { marginBottom: '16px' }
                        //         : {
                        //               marginTop: '16px',
                        //               marginBottom: '16px',
                        //           }
                        // }
                        >
                            <PriceDisplay activeplan={activeplan} t={t} />
                        </div>

                        <button
                            ref={ref as React.LegacyRef<HTMLButtonElement>}
                            id="oldPricingCTA"
                            className="pricingCTA-shiny-button"
                            onClick={onPricingClickHandler}
                        >
                            {t('Get Started')}
                        </button>
                        <div id="pricePoints">
                            <div className="pricePoint">
                                <CheckIconBlack />
                                <p>
                                    {t('instant')} <span>{t('answers')}</span>
                                </p>
                            </div>
                            <div className="pricePoint">
                                <CheckIconBlack />
                                <p>
                                    <span>{t('10 Million+')}</span>{' '}
                                    {t('Questions')} {t('Solved')}
                                </p>
                            </div>
                            <div className="pricePoint">
                                <CheckIconBlack />
                                <p>
                                    {t('powered_by')}{' '}
                                    <span>{t('cutting_edge_ai')}</span>
                                </p>
                            </div>
                            <div className="pricePoint">
                                <CheckIconBlack />
                                <p>
                                    {t('Fully')}{' '}
                                    <span>{t('Undetectable')}</span>
                                </p>
                            </div>
                            <div className="pricePoint">
                                <CheckIconBlack />
                                <p>
                                    <span>{t('plagiarism_free')}</span>
                                </p>
                            </div>
                            <div className="pricePoint">
                                <CheckIconBlack />
                                <p>
                                    <span>{t('530K+')}</span> {t('students')}
                                </p>
                            </div>
                        </div>
                        <hr className="hr-line"></hr>
                        <div id="priceBenefits">
                            {t('cancel_anytime')}
                            {'5.99'}
                            {t('per_week')}
                        </div>
                        <div id="paymentMethodContainer">
                            <div className="cardsContainer">
                                <img src={icons.visaBordered} alt="visa" />
                                <img src={icons.mastercardBordered} alt="ms" />
                                <img src={icons.amexBordered} alt="amex" />
                                <img className="gpay" src={icons.gpayBordered} alt="gpay" />
                                <img src={icons.applepayBordered} alt="applepay" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id="priceDesktopContainer">
                        <div id="pricingDesktop">
                            <div id="priceTaglineDesktop">
                                {activeplan === 'weekly' ? (
                                    <p>
                                        {t('72 hour')}{' '}
                                        <span>{'Unlimited Access'}</span>
                                    </p>
                                ) : (
                                    <p>
                                        {t('Save')} <span>{'$33'}</span> with{' '}
                                        <span>{'SEMESTER PLAN'}</span>
                                    </p>
                                )}
                            </div>

                            <div style={{ marginTop: '16px' }}>
                                <PriceDisplay activeplan={activeplan} t={t} />
                            </div>

                            <button
                                id="oldPricingCTA"
                                className="pricingCTA-shiny-button"
                                ref={ref as React.LegacyRef<HTMLButtonElement>}
                                onClick={onPricingClickHandler}
                            >
                                {t('Get Started')}
                            </button>
                            <div id="pricePoints">
                                <div className="pricePoint">
                                    <CheckIconBlack />
                                    <p>
                                        {t('instant')}{' '}
                                        <span>{t('answers')}</span>
                                    </p>
                                </div>
                                <div className="pricePoint">
                                    <CheckIconBlack />
                                    <p>
                                        {t('Fully')}{' '}
                                        <span>{t('Undetectable')}</span>
                                    </p>
                                </div>
                                <div className="pricePoint">
                                    <CheckIconBlack />
                                    <p>
                                        <span>{t('10 Million+')}</span>{' '}
                                        {t('Questions')} {t('Solved')}
                                    </p>
                                </div>

                                <div className="pricePoint">
                                    <CheckIconBlack />
                                    <p>
                                        <span>{t('plagiarism_free')}</span>
                                    </p>
                                </div>
                                <div className="pricePoint">
                                    <CheckIconBlack />
                                    <p>
                                        {t('powered_by')}{' '}
                                        <span>{t('cutting_edge_ai')}</span>
                                    </p>
                                </div>
                                <div className="pricePoint">
                                    <CheckIconBlack />
                                    <p>
                                        <span>{t('530K+')}</span>{' '}
                                        {t('students')}
                                    </p>
                                </div>
                            </div>
                            <div id="priceBenefits">
                                {t('cancel_anytime_2')}
                                {'5.99'}
                                {t('per_week')}
                            </div>
                        </div>
                        <hr className="hr-line"></hr>
                        <div id="paymentMethodContainer">
                            <div className="cardsContainer">
                                <img src={icons.visaBordered} alt="visa" />
                                <img src={icons.mastercardBordered} alt="ms" />
                                <img src={icons.amexBordered} alt="amex" />
                                <img className="gpay" src={icons.gpayBordered} alt="gpay" />
                                <img src={icons.applepayBordered} alt="applepay" />
                            </div>
                            <img src={TenMQues} alt="TenMQues" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
