import React, { useEffect, useState } from 'react';
import { Header } from './Header';
import { Hero } from './Hero';
import { Institutions } from './Institutions';
import { PictureReviews } from './PictureReviews';
import { Pricing } from './Pricing';
import { HowItWorks } from './HowItWorks';
import { CompanyLogos } from './CompanyLogos';
import { Faq } from './Faq';
import { Footer } from './Footer';
import { StickyPricing } from './StickyPricing';

import {
    useAuth,
    useDisableBodyScroll,
    useScreenSize,
    useScrollIntoView,
} from '~hooks';
import { CookieKeys } from '~constants';

import { TrialPopup } from '~components/Modals/TrialPopup';
import { NewsletterPopup } from '~components/Modals/Newsletter';
import { setCookie } from '~utils';

// import { ChatGPTSection } from '~pages/Homepage/ChatGPTSection/ChatGPTSection';
import { CTA } from '~pages/Homepage/CTA/CTA';
// import { Testimonials } from './Testimonials';

import './Homepage.styles.scss';
import { Numbers } from './Numbers';
// import { DisturbancePopup } from '~components/Modals/DisturbancePopup';
import { Tools } from './ToolsSection';
import NewFeature from './NewFeatures/NewFeature';
import NewReviews from './NewReviews/NewReviews';
import BlurBackdropPopup from '~components/Modals/BlurBackdropPopup/BlurBackdropPopup';

export const Homepage = () => {
    const [showPopupCheckout, setShowPopupCheckout] = useState(false);
    const [checkoutPage, setCheckoutPage] = useState(false);
    const [isTrialPopupShow, setIsTrialPopupShow] = useState(false);
    const [isNewsLetterPopupShow, setIsNewsLetterPopupShow] = useState(false);

    // const [disturbancePopup, setDisturbancePopup] = useState(false);

    const { updateAuthState, isAuthenticated } = useAuth();
    const { isMobile } = useScreenSize();

    const checkAuth = async () => {
        console.log('priv auth >>>:');
        await updateAuthState();
    };

    const checkReferral = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        const fbclid = urlParams.get('fbclid');
        const tuneTransactionId = urlParams.get('transaction_id');
        if (ref) {
            setCookie(CookieKeys.referralCookie, ref, 60);
        }
        if (fbclid) {
            setCookie(CookieKeys.fbClickId, fbclid, 60);
        }
        if (tuneTransactionId) {
            setCookie(CookieKeys.tuneTransactionId, tuneTransactionId, 60);
        }
    };

    useDisableBodyScroll(showPopupCheckout);

    const handlePopupCheckout = () => {
        window.open("https://studybuddy.gg/register", "_self");
    };


    const handleOpenTrialPopup = () => {
        setIsTrialPopupShow(true);
    };

    const handleCloseTrialPopup = () => {
        localStorage.setItem('isTrialAvailable', 'false');
        setIsTrialPopupShow(false);
    };

    const handleOpenNewsLetterPopup = () => {
        setIsNewsLetterPopupShow(true);
        setIsTrialPopupShow(false);
    };

    const handleCloseNewsLetterPopup = () => {
        localStorage.setItem('isNewsletterAvailable', 'false');
        setIsNewsLetterPopupShow(false);
    };

    const checkIsTrialAvailable = () => {
        const isTrialAvailable = localStorage.getItem('isTrialAvailable');
        if (!isTrialAvailable) {
            handleOpenTrialPopup();
        }
    };

    const checkIsNewsletterAvailable = () => {
        const isNewsletterAvailable = localStorage.getItem(
            'isNewsletterAvailable',
        );
        if (!isNewsletterAvailable) {
            handleOpenNewsLetterPopup();
        }
    };

    /* const disturbancePopupHandler = () => {
        setDisturbancePopup(true);
    };

    const disturbancePopupCloseHandler = () => {
        setDisturbancePopup(false);
    }; */

    useEffect(() => {
        checkReferral();
        checkAuth();
        const TrialTimer = setTimeout(checkIsTrialAvailable, 10000);
        const NewsLetterTimer = setTimeout(checkIsNewsletterAvailable, 60000);
        if (isNewsLetterPopupShow) {
            clearTimeout(TrialTimer);
            if (isTrialPopupShow) {
                setIsTrialPopupShow(false);
            }
        }
        /* const DisturbanceTimer = setTimeout(disturbancePopupHandler, 500); */
        return () => {
            clearTimeout(TrialTimer);
            clearTimeout(NewsLetterTimer);
        };
    }, []);

    const [isPricingInView, setIsPricingInView] = useState(false);

    const scrollToPricing = () => {
        let pricingEle = document.getElementById('pricing-section');
        pricingEle?.scrollIntoView({ behavior: 'smooth' });
    };

    const { toggleRender } = useScrollIntoView(300);
    return (
        <div className="homepage">
            <Header scrollToPricing={scrollToPricing} />
            <Hero
                setIsPricingInView={setIsPricingInView}
                scrollToPricing={scrollToPricing}
            />
            <CompanyLogos />
            <PictureReviews />
            <Numbers />
            <HowItWorks />
            <Pricing
                handleCTA={handlePopupCheckout}
                setIsPricingInView={setIsPricingInView}
            />
            <NewFeature />
            {/* <Testimonials /> */}
            {/* <ChatGPTSection /> */}
            {/* <Features /> */}
            <NewReviews onImageLoad={toggleRender} />
            {!isMobile && <Tools />}
            <Faq />
            <Institutions />
            <CTA
                handleCTA={handlePopupCheckout}
                setIsPricingInView={setIsPricingInView}
            />
            <Footer scrollToPricing={scrollToPricing} />
            <StickyPricing
                showCheckout={showPopupCheckout}
                handleCTA={handlePopupCheckout}
                isPricingInView={isPricingInView}
            />
        
            <TrialPopup
                show={isTrialPopupShow && !showPopupCheckout}
                handleClose={handleCloseTrialPopup}
                handleCTA={handlePopupCheckout}
            />
            <NewsletterPopup
                show={isNewsLetterPopupShow && !showPopupCheckout}
                handleClose={handleCloseNewsLetterPopup}
            />
        </div>
    );
};
