import { HowItWorksItemProps } from './HowItWorksItem';
import { i18n } from 'i18next';

export const getHowItWorksData = (t: i18n['t']): HowItWorksItemProps[] => {
    return [
        {
            title: 'injects_into',
            initialState: true,
            subtitle: 'learning_platform',
            highLightedComponent: 'subtitle',
            content: `${t('when_enabled')} <span>${t('ask_studybuddy_btn')}</span> ${t('one_click_explanations')}`,
            videoSrc:
                'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/buttonVideo_new.mp4',
        },
        {
            title: 'screenshot',
            subtitle: 'any_question',
            content: `${t("don't_use_button_lowkey")} <span>${t('screenshot...')}</span> ${t('and_wallah!')}`,
            videoSrc:
                'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/screenshotVideo_new.mp4',
        },
        {
            title: 'answers',
            subtitle: 'anything_&_everything',
            content: `${t('quiz')} <span>${t('test')}</span> <span>${t('homework')}</span> ${t('or_problem_set')} <span>${t('math')}</span> ${t('ques_no_problem')} <span>${t('images')}</span> <span>${t('graphs')}</span> <span>${t('diagrams')}</span>${t('?_easy_peasy')}`,
            videoSrc:
                'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Works_on_anything.mp4',
        },
        {
            title: '100%',
            subtitle: 'undetectable',
            content: `${t('your_info_is_safe')} <br /><strong>Studybuddy</strong> ${t('prevents_extension_detection')}`,
            videoSrc:
                'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Hidden.mp4',
        },  
    ];
};
