import React from 'react';

import { useTranslation } from 'react-i18next';

import './PictureReviews.styles.scss';

export const PictureReviews = () => {
    const { t } = useTranslation();

    return (
        <div id="pictureReviews">
            <h1 className="pictureReviewsTitle">
                {/* {t('by_robots')}, <span>{t('for_humans')}</span> */}
                {t('real_people')}, {t('real_results')}
            </h1>
            <h1 className="pictureReviewsTitleMobile">
                <span>{t('real_results')}</span>
            </h1>
            <div className="pictureReviewsContainer">
                <div className="pictureReviewsGrid">
                    <div className="review-item">
                        <img
                            src="/assets/images/picture-reviews/5.jpg"
                            alt="review"
                        />
                    </div>
                    <div className="review-item">
                        <img
                            src="/assets/images/picture-reviews/1.jpg"
                            alt="review"
                        />
                    </div>
                    <div className="review-item">
                        <img
                            src="/assets/images/picture-reviews/2.jpg"
                            alt="review"
                        />
                    </div>
                    <div className="review-item">
                        <img
                            src="/assets/images/picture-reviews/7.jpg"
                            alt="review"
                        />
                    </div>
                    <div className="review-item">
                        <img
                            src="/assets/images/picture-reviews/3.jpg"
                            alt="review"
                        />
                    </div>
                    <div className="review-item">
                        <img
                            src="/assets/images/picture-reviews/4.jpg"
                            alt="review"
                        />
                    </div>
                    <div className="review-item">
                        <img
                            src="/assets/images/picture-reviews/6.jpg"
                            alt="review"
                        />
                    </div>
                </div>
            </div>
            <div className="pictureReviewsContainerMobile">
                <div className="pictureReviewsMarquee">
                    <div className="pictureReviewsMarqueeWrap">
                        <div className="pictureReviewsGrid">
                            <div className="review-item">
                                <img
                                    src="/assets/images/picture-reviews/7.jpg"
                                    alt="review"
                                />
                            </div>
                            <div className="review-item">
                                <img
                                    src="/assets/images/picture-reviews/1.jpg"
                                    alt="review"
                                />
                            </div>
                            <div className="review-item">
                                <img
                                    src="/assets/images/picture-reviews/2.jpg"
                                    alt="review"
                                />
                            </div>
                            <div className="review-item">
                                <img
                                    src="/assets/images/picture-reviews/3.jpg"
                                    alt="review"
                                />
                            </div>
                            <div className="review-item">
                                <img
                                    src="/assets/images/picture-reviews/4.jpg"
                                    alt="review"
                                />
                            </div>
                            <div className="review-item">
                                <img
                                    src="/assets/images/picture-reviews/5.jpg"
                                    alt="review"
                                />
                            </div>
                            <div className="review-item">
                                <img
                                    src="/assets/images/picture-reviews/6.jpg"
                                    alt="review"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
