import React from 'react';

import { institutionsItem } from './Institutions.constants';

import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';

import './Institutions.styles.scss';

export const Institutions = () => {
    const { t } = useTranslation();

    return (
        <div id="institutions">
            <h3 className="desktop">
                {t('used_by')}{' '}
                <span>
                    {t('530k')}+ {t('students')}
                </span>{' '}
                {t('at')}
            </h3>
            <h3 className="mobile">
                {t('used_by')} <span>{t('530k')}+</span> {t('students')}{' '}
                {t('at')}
            </h3>

            <Marquee className="institution-marquee-container">
                {institutionsItem.map((item, index) => (
                    <div className="institution-marquee-item" key={index}>
                        <img
                            className="marquee-image"
                            src={item}
                            alt="institution"
                        />
                    </div>
                ))}
            </Marquee>
        </div>
    );
};
