import './HowItWorks.styles.scss';

import { useTranslation } from 'react-i18next';
import { HowItWorksItem } from './HowItWorksItem';
import { getHowItWorksData } from './HowItWorks.constant';

export const HowItWorks = () => {
    const { t } = useTranslation();
    const howItWorksItems = getHowItWorksData(t);

    return (
        <div id="howItWorks">
            <h1 className="howItWorksTitle">
                {t('how')} {t('it_works')}
            </h1>
            <div className="howItWorksContainer">
                <div
                    style={{ justifySelf: 'self-end' }}
                    className="howItWorksItem"
                >
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>
                                {t('injects_into')} {t('learning_platform')}
                            </h3>
                            <p>
                                {t('when_enabled')}{' '}
                                <span>{t('ask_studybuddy_btn')}</span>{' '}
                                {t('one_click_explanations')}
                            </p>
                        </div>
                        <video
                            id="buttonVideo"
                            className="videoContent"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/buttonVideo_new.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>

                <div className="howItWorksItem">
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>
                                {t('screenshot')} {t('any_question')}
                            </h3>
                            <p>
                                {t("don't_use_button_lowkey")}{' '}
                                <span>{t('screenshot...')}</span>{' '}
                                {t('and_wallah!')}
                            </p>
                        </div>
                        <video
                            className="videoContent"
                            id="screenshotVideo"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/screenshotVideo_new.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>

                <div
                    style={{
                        justifySelf: 'self-end',
                        marginTop: '16px',
                    }}
                    className="howItWorksItem"
                >
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>
                                {t('100%')} {t('undetectable')}
                            </h3>
                            <p>
                                {t('your_info_is_safe')} <br />
                                <strong>{`Studybuddy`}</strong>{' '}
                                {t('prevents_extension_detection')}
                            </p>
                        </div>
                        <video
                            className="videoContent"
                            id="hiddenVideo"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Hidden.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>

                <div style={{ marginTop: '16px' }} className="howItWorksItem">
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>
                                {t('answers')} {t('anything_&_everything')}
                            </h3>

                            <p>
                                <span>{t('quiz')}</span>{' '}
                                <span>{t('test')}</span>{' '}
                                <span>{t('homework')}</span>{' '}
                                {t('or_problem_set')} <span>{t('math')}</span>{' '}
                                {t('ques_no_problem')}{' '}
                                <span>{t('images')}</span>{' '}
                                <span>{t('graphs')}</span>{' '}
                                <span>{t('diagrams')}</span>
                                {t('?_easy_peasy')}
                            </p>
                        </div>
                        <video
                            className="videoContent"
                            id="anythingVideo"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Works_on_anything.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="howItWorksMobileContainer">
                <hr className="howItWorksItemDivider" />
                {howItWorksItems.map((item, index) => (
                    <HowItWorksItem
                        key={index}
                        title={item.title}
                        subtitle={item.subtitle}
                        content={item.content}
                        videoSrc={item.videoSrc}
                        highLightedComponent={item.highLightedComponent}
                        initialState={item.initialState}
                    />
                ))}
            </div>
        </div>
    );
};
