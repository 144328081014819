import React, { FC } from 'react';

import './ToolsPopup.styles.scss';

import WriterCreateDesktop from '~assets/writer-create-desktop.svg';
import WriterCreateGif from '~assets/videos/writer-create.gif';

type CardProps = {
    cardStyles: React.CSSProperties;
    TitleImg: React.ComponentType;
    animatedImg: string;
};

export const PopUpDescriptionCard: FC<CardProps> = ({
    cardStyles,
    TitleImg,
    animatedImg,
}) => {
    return (
        <div className="description-card-container" style={cardStyles}>
            <TitleImg />
            <img
                className="animatedContent"
                src={animatedImg}
                alt="animated-content"
            />
        </div>
    );
};
