import { Route, Routes as ReactRouterRoutes, Navigate } from 'react-router-dom';

import { AppRoutes } from '~constants';
import { Homepage } from '~pages/Homepage';

import { Privacy } from '~pages/Privacy';
import { Terms } from '~pages/Terms';


export const Routes = () => {
    return (
        <ReactRouterRoutes>
            <Route path="*" element={<Navigate to={AppRoutes.Home} />} />
            <Route path={AppRoutes.Home} element={<Homepage />} />
            <Route path={AppRoutes.Privacy} element={<Privacy />} />
            <Route path={AppRoutes.Terms} element={<Terms />} />
            
        </ReactRouterRoutes>
    );
};
