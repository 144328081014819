import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { AppRoutes, CookieKeys } from '~constants';
import { UserDataState, clearUser, setUser } from '~redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { logger, setCookie } from '~utils';
import { getStripeUserDetails } from '~components/PaymentPlan';
import { useTranslation } from 'react-i18next';

interface AuthContext {
    isAuthenticated: boolean;
    user: UserDataState | null;
    planStatus: string | undefined;
    token: string | undefined;
    logout: () => Promise<void>;
    checkAuthState: () => Promise<any>;
    updateAuthState: () => Promise<any>;
    sendAuthToExtension: () => void;
}

export const useAuth = (): AuthContext => {
    const user: UserDataState = useSelector((state: any) => state.user);
    const { t } = useTranslation();
    const { isAuthenticated, planStatus, token, data } = user;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const updateAuthState = async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });
            if (currentUser) {
                logger.debug('updateAuthState:', currentUser);
                dispatch(setUser(currentUser));
            }
        } catch (err) {
            dispatch(clearUser());
        }
    };
    // When the user is updated we send a notificaiton to the extension
    useEffect(() => {
        logger.debug('User value changed: ', user?.data);
        // Send token to Chrome extension
        if (user?.data) {
            sendAuthToExtension();
        }
    }, [user]);

    const sendAuthToExtension = async () => {
        try {
            const billingData = await getStripeUserDetails(
                user?.data?.signInUserSession?.idToken?.jwtToken,
                String(data?.attributes['custom:stripeCustomerID']),
                t,
            );
            const payload = {
                IdToken: user?.data?.signInUserSession?.idToken?.jwtToken,
                AccessToken:
                    user?.data?.signInUserSession?.accessToken?.jwtToken,
                RefreshToken:
                    user?.data?.signInUserSession?.refreshToken?.token,
                customerId: String(data?.attributes['custom:stripeCustomerID']),
                turboModeExpiry: data?.attributes['custom:turbo_expired_at']
                    ? String(data?.attributes['custom:turbo_expired_at'])
                    : '',
                paymentMethodId: billingData?.paymentMethodId,
                planNickName: billingData?.planNickName,
            };
            logger.debug('sign in user session is ', payload);
            setCookie(CookieKeys.idTokenCookie, payload.IdToken, 999);
            setCookie(CookieKeys.accessTokenCookie, payload.AccessToken, 999);
            setCookie(CookieKeys.refreshTokenCookie, payload.RefreshToken, 999);
            setCookie('cus_id', payload.customerId, 999);
            setCookie('tm_ex_date', payload.turboModeExpiry, 999);
            setCookie('paym_id', payload.paymentMethodId, 999);
            setCookie('pnn', payload.planNickName, 999);
            let id = String(data?.attributes['custom:stripeCustomerID']);
            if (id) {
                id = id.replace('cus_', '');
                setCookie(CookieKeys.userIdCookie, id, 999);
            }
            logger.debug('Set plan status in cookies: ', planStatus);
            if (planStatus) {
                if (planStatus.startsWith('paused')) {
                    const date = planStatus.split(': ')[1];
                    const dateInt = parseInt(date);
                    console.log('Date int is>>> ', dateInt);
                    if (dateInt === 1725152400) {
                        setCookie(CookieKeys.accountStatus, 'active', 999);
                    } else {
                        setCookie(CookieKeys.accountStatus, planStatus, 999);
                    }
                } else {
                    setCookie(CookieKeys.accountStatus, planStatus, 999);
                }
            }
        } catch (error) {
            logger.warn('Failed to save auth cookie: ' + error);
        }
    };

    const checkAuthState = async () => {
        logger.debug('checkAuthState useeffect>>', user);
        if (!isAuthenticated) {
            try {
                const currentUser = await Auth.currentAuthenticatedUser({
                    bypassCache: true,
                });
                if (currentUser) {
                    dispatch(setUser(currentUser));
                    return {
                        data: currentUser,
                        isAuthenticated: true,
                        planStatus:
                            currentUser?.attributes[
                                'custom:stripeAccountStatus'
                            ] ||
                            currentUser?.attributes[
                                'custom:paypalAccountStatus'
                            ],
                        token: currentUser?.signInUserSession?.accessToken
                            ?.jwtToken,
                    };
                } else {
                    return null;
                }
            } catch (err) {
                logger.debug('Check Auth Error >>', err);
            }
        }
        return user;
    };

    const clearAuthCookies = () => {
        try {
            logger.debug('Clearing auth cookies');
            setCookie(CookieKeys.idTokenCookie, '', -1);
            setCookie(CookieKeys.accessTokenCookie, '', -1);
            setCookie(CookieKeys.refreshTokenCookie, '', -1);
            setCookie(CookieKeys.userIdCookie, '', -1);
        } catch (error) {
            logger.warn('Failed to clear auth cookies: ' + error);
        }
    };

    const logout = async () => {
        try {
            const promises = [];
            promises.push(Auth.signOut());
            logger.info('User is null, clearing auth cookies...');
            clearAuthCookies();
            dispatch(clearUser());
            navigate(AppRoutes.Signout_Redirect);
        } catch (err: any) {
            logger.error(err);
        }
    };

    return {
        isAuthenticated,
        planStatus,
        token,
        user,
        updateAuthState,
        checkAuthState,
        logout,
        sendAuthToExtension,
    };
};
