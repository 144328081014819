import { useScreenSize } from '~hooks';
import { ReactComponent as StudybuddyWriterIcon } from '~assets/studybuddy-writer.svg';
import { ReactComponent as StudybuddyTurboIcon } from '~assets/studybuddy-turbo.svg';
import { ReactComponent as StudybuddyAppIcon } from '~assets/studybuddy-app.svg';

import { ReactComponent as WriterDescriptionDesktop } from '~/assets/writer-description-desktop.svg';
import { ReactComponent as WriterDescriptionMobile } from '~/assets/writer-description-mobile.svg';
import { ReactComponent as AppDescriptionMobile } from '~/assets/app-description-mobile.svg';
import { ReactComponent as AppDescriptionDesktop } from '~/assets/app-description-desktop.svg';
import { ReactComponent as TurboImgDesktop } from '~assets/turbo-img-desktop.svg';
import { ReactComponent as TurboImgMobile } from '~assets/turbo-img-mobile.svg';

import { ReactComponent as AppTextDesktop } from '~assets/app-text-desktop.svg';
import { ReactComponent as AppTextMobile } from '~assets/app-text-mobile.svg';

import { ReactComponent as TurboTextDesktop } from '~assets/turbo-text-desktop.svg';
import { ReactComponent as TurboTextMobile } from '~assets/turbo-text-mobile.svg';

export function useToolsCardData() {
    const { isMobile } = useScreenSize();

    const toolsCardData = [
        {
            id: 1,
            isTurbo: false,
            variant: 'writer',
            TitleImageSrc: StudybuddyWriterIcon,
            description: isMobile
                ? 'Craft compelling content effortlessly. Write better, faster, and with confidence.'
                : 'Craft compelling content effortlessly with smart suggestions and grammar checks. Write better, faster, and with confidence.',
            DescriptionImgSrc: isMobile
                ? WriterDescriptionMobile
                : WriterDescriptionDesktop,
            descriptionImgStyle: isMobile ? { top: '22%' } : { top: '23%' },
        },
        {
            id: 2,
            isTurbo: false,
            variant: 'app',
            TitleImageSrc: StudybuddyAppIcon,
            DescriptionTextImg: isMobile ? AppTextMobile : AppTextDesktop,
            DescriptionImgSrc: isMobile
                ? AppDescriptionMobile
                : AppDescriptionDesktop,
            descriptionImgStyle: { top: '18%' },
        },
        {
            id: 3,
            isTurbo: true,
            variant: 'turbo',
            TitleImageSrc: StudybuddyTurboIcon,
            DescriptionTextImg: isMobile ? TurboTextMobile : TurboTextDesktop,
            DescriptionImgSrc: isMobile ? TurboImgMobile : TurboImgDesktop,
            descriptionImgStyle: isMobile ? { top: '67%' } : { top: '65%' },
        },
    ];

    return toolsCardData;
}
