import React from 'react';

import { Header } from '~pages/Homepage/Header';
import { Footer } from '~pages/Homepage/Footer';
import {
    agreement_legal_term_description,
    contents,
    term_1,
    term_3,
    term_4,
    term_8,
    term_9,
    term_12,
    term_13,
    term_14,
    term_15,
    term_16,
    term_17,
    term_18,
    term_19,
    term_20,
    term_21,
    term_22,
    term_25,
} from './TermsConstant';
import './Terms.styles.scss';

export const Terms = () => {
    return (
        <div className="terms-page">
            <Header />
            <div className="terms-service-parent">
                <h1 className="terms-service-heading">Terms of Service</h1>
                <h2 className="light-gray-text">Updated: August 02, 2024</h2>
                <div className="term-section-container">
                    <h2 className="term-section-title">
                        AGREEMENT TO OUR LEGAL TERMS
                    </h2>
                    <p className="terms-description">
                        {' '}
                        We are Studybuddy2 LLC ("Company," "we," "us," "our"), a
                        company operating the website{' '}
                        <a className="underline" href="https://studybuddy.gg/">
                            https://studybuddy.gg/
                        </a>{' '}
                        (the "Site"), as well as any other related products and
                        services that refer or link to these legal terms (the
                        "Legal Terms") (collectively, the "Services").
                        <br />
                        <br />
                        You can contact us by email at{' '}
                        <a href="support@studybuddy.gg.">
                            support@studybuddy.gg.
                        </a>
                        <br />
                        <br />
                        {agreement_legal_term_description}
                        <br />
                        <br />
                    </p>
                    <h2 className="light-gray-text recommendation ">
                        We recommend that you print a copy of these Legal Terms
                        for your records.
                    </h2>
                </div>
                <div className="term-section-container">
                    <h2 className="content-title">TABLE OF CONTENTS</h2>
                    <ol className="contents-container">
                        {contents?.map((content) => <li> {content} </li>)}
                    </ol>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title"> {term_1.title} </h2>
                    <p className="terms-description">{term_1.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">
                        2. INTELLECTUAL PROPERTY RIGHTS
                    </h2>
                    <p className="terms-description">
                        <p className="term-section-sub-title">
                            Our Intellectual Property
                        </p>
                        <br />
                        We are the owner or the licensee of all intellectual
                        property rights in our Services, including all source
                        code, databases, functionality, software, website
                        designs, audio, video, text, photographs, and graphics
                        in the Services (collectively, the "Content"), as well
                        as the trademarks, service marks, and logos contained
                        therein (the "Marks").
                        <br /> <br />
                        Our Content and Marks are protected by copyright and
                        trademark laws (and various other intellectual property
                        rights and unfair competition laws) and treaties in the
                        United States and around the world.
                        <br /> <br />
                        The Content and Marks are provided in or through the
                        Services "AS IS" for your personal, non-commercial use
                        or internal business purpose only.
                    </p>
                    <p className="terms-description">
                        <p className="term-section-sub-title">
                            Your Use of Our Services
                        </p>
                        <br />
                        Subject to your compliance with these Legal Terms,
                        including the "PROHIBITED ACTIVITIES" section below, we
                        grant you a non-exclusive, non-transferable, revocable
                        license to:
                        <br />
                        <br />
                        <div className="terms-description-list">
                            <ul>
                                <li>access the Services; and</li>
                                <li>
                                    download or print a copy of any portion of
                                    the Content to which you have properly
                                    gained access,
                                </li>
                            </ul>
                        </div>
                        <br />
                        solely for your personal, non-commercial use or internal
                        business purpose.
                        <br />
                        <br />
                        Except as set out in this section or elsewhere in our
                        Legal Terms, no part of the Services and no Content or
                        Marks may be copied, reproduced, aggregated,
                        republished, uploaded, posted, publicly displayed,
                        encoded, translated, transmitted, distributed, sold,
                        licensed, or otherwise exploited for any commercial
                        purpose whatsoever, without our express prior written
                        permission.
                        <br />
                        <br />
                        If you wish to make any use of the Services, Content, or
                        Marks other than as set out in this section or elsewhere
                        in our Legal Terms, please address your request to:
                        support@studybuddy.gg. If we ever grant you the
                        permission to post, reproduce, or publicly display any
                        part of our Services or Content, you must identify us as
                        the owners or licensors of the Services, Content, or
                        Marks and ensure that any copyright or proprietary
                        notice appears or is visible on posting, reproducing, or
                        displaying our Content.
                        <br />
                        <br />
                        We reserve all rights not expressly granted to you in
                        and to the Services, Content, and Marks.
                        <br />
                        <br />
                        Any breach of these Intellectual Property Rights will
                        constitute a material breach of our Legal Terms and your
                        right to use our Services will terminate immediately.
                    </p>
                    <p className="terms-description">
                        <p className="term-section-sub-title">
                            Your Submissions
                        </p>
                        <br />
                        Please review this section and the "PROHIBITED
                        ACTIVITIES" section carefully prior to using our
                        Services to understand the (a) rights you give us and
                        (b) obligations you have when you post or upload any
                        content through the Services.
                    </p>
                    <p className="terms-description">
                        <strong>Submissions:</strong> By directly sending us any
                        question, comment, suggestion, idea, feedback, or other
                        information about the Services ("Submissions"), you
                        agree to assign to us all intellectual property rights
                        in such Submission. You agree that we shall own this
                        Submission and be entitled to its unrestricted use and
                        dissemination for any lawful purpose, commercial or
                        otherwise, without acknowledgment or compensation to
                        you.
                    </p>
                    <p className="terms-description">
                        <strong>
                            You are responsible for what you post or upload:
                        </strong>
                        By sending us Submissions through any part of the
                        Services you:
                        <br />
                        <div className="terms-description-list">
                            <ul>
                                <li>
                                    confirm that you have read and agree with
                                    our "PROHIBITED ACTIVITIES" and will not
                                    post, send, publish, upload, or transmit
                                    through the Services any Submission that is
                                    illegal, harassing, hateful, harmful,
                                    defamatory, obscene, bullying, abusive,
                                    discriminatory, threatening to any person or
                                    group, sexually explicit, false, inaccurate,
                                    deceitful, or misleading;
                                </li>
                                <li>
                                    to the extent permissible by applicable law,
                                    waive any and all moral rights to any such
                                    Submission;
                                </li>
                                <li>
                                    warrant that any such Submission is original
                                    to you or that you have the necessary rights
                                    and licenses to submit such Submissions and
                                    that you have full authority to grant us the
                                    above-mentioned rights in relation to your
                                    Submissions; and
                                </li>
                                <li>
                                    warrant and represent that your Submissions
                                    do not constitute confidential information.
                                </li>
                            </ul>
                        </div>
                    </p>
                    <p className="terms-description">
                        You are solely responsible for your Submissions and you
                        expressly agree to reimburse us for any and all losses
                        that we may suffer because of your breach of (a) this
                        section, (b) any third party's intellectual property
                        rights, or (c) applicable law.
                    </p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_3.title}</h2>
                    <p className="terms-description">{term_3.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_4.title}</h2>
                    <p className="terms-description">{term_4.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">
                        5. PURCHASES AND PAYMENTS{' '}
                    </h2>
                    <p className="terms-description">
                        We may provide paid products and/or services within the
                        Service.
                        <br />
                        <br />
                        You agree to provide current, complete, and accurate
                        purchase and account information for all purchases made
                        via the Services. You further agree to promptly update
                        account and payment information, including email
                        address, payment method, and payment card expiration
                        date, so that we can complete your transactions and
                        contact you as needed. Sales tax will be added to the
                        price of purchases as deemed required by us. We may
                        change prices at any time. All payments shall be in US
                        dollars or the equivalent exchange rate.
                        <br />
                        <br />
                        You agree to pay all charges at the prices then in
                        effect for your purchases and any applicable shipping
                        fees, and you authorize us to charge your chosen payment
                        provider for any such amounts upon placing your order.
                        We reserve the right to correct any errors or mistakes
                        in pricing, even if we have already requested or
                        received payment.
                        <br />
                        <br />
                        We use third-party services for payment processing
                        (e.g., payment processors). We will not store or collect
                        your payment card details. That information is provided
                        directly to our third-party payment processors whose use
                        of your personal information is governed by their
                        Privacy Policy. These payment processors adhere to the
                        standards set by PCI-DSS as managed by the PCI Security
                        Standards Council, which is a joint effort of brands
                        like Visa, MasterCard, American Express, and Discover.
                        PCI-DSS requirements help ensure the secure handling of
                        payment information. The payment processors we work with
                        are:
                        <br />
                        <br />
                        <div className="terms-description-list">
                            <ul>
                                <li>
                                    <strong>Stripe:</strong> Their Privacy
                                    Policy can be viewed at{' '}
                                    <a
                                        className="underline"
                                        href="https://stripe.com/us/privacy
                                    "
                                        target="_blank"
                                    >
                                        https://stripe.com/us/privacy
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <br />
                        We reserve the right to refuse any order placed through
                        the Services. We may, in our sole discretion, limit or
                        cancel quantities purchased per person, per household,
                        or per order. These restrictions may include orders
                        placed by or under the same customer account, the same
                        payment method, and/or orders that use the same billing
                        or shipping address. We reserve the right to limit or
                        prohibit orders that, in our sole judgment, appear to be
                        placed by dealers, resellers, or distributors.
                    </p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">6. SUBSCRIPTIONS</h2>
                    <p>
                        <p className="terms-description">
                            <p className="term-section-sub-title">
                                Billing and Renewal
                            </p>
                            <br />
                            Subscriptions renew automatically unless canceled.
                            By subscribing, you consent to recurring charges
                            without prior approval for each charge, until
                            cancellation. The billing cycle depends on the
                            subscription plan.
                        </p>
                        <br />
                        <p className="terms-description">
                            <p className="term-section-sub-title">
                                Cancellation and Refund Policy
                            </p>
                            <br />
                            We are committed to your satisfaction with our
                            services and offer a straightforward refund policy
                            to address any concerns you may have.
                            <br />
                            <br />
                            1-Week Subscription: No refunds are available, as
                            you’ve already had access to a 3-day trial for
                            $0.99.
                            <br />
                            Semestral Subscription: You may request a refund
                            within 3 days of purchase, provided you can supply
                            evidence of your dissatisfaction.
                            <br />
                            <br />
                            To request a refund or cancel your subscription,
                            please contact us via email at{' '}
                            <a href="https://studybuddy.gg/" target="_blank">
                                support@studybuddy.gg.
                            </a>
                            . We’re here to help resolve any issues promptly.
                        </p>
                        <br />

                        <p className="terms-description">
                            <p className="term-section-sub-title">
                                Fee Changes
                            </p>
                            <br />
                            Subscription fees may change, and we will notify you
                            as required by law.
                        </p>
                    </p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">
                        7. PROHIBITED ACTIVITIES
                    </h2>
                    <p className="terms-description">
                        You may not use StudyBuddy for any unauthorized
                        purposes, including:
                        <br />
                        <br />
                        <div className="terms-description-list">
                            <ul>
                                <li>
                                    Retrieving data or content to create
                                    compilations or databases without
                                    permission.
                                </li>
                                <li>
                                    Misleading others or gaining sensitive
                                    information.
                                </li>
                                <li>
                                    Circumventing security features or copying
                                    content for commercial purposes.
                                </li>
                                <li>
                                    Harassing, abusing, or harming others, or
                                    engaging in any illegal activity.
                                </li>
                                <li>
                                    Using automated tools or unauthorized
                                    scripts.
                                </li>
                            </ul>
                        </div>
                    </p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_8.title}</h2>
                    <p className="terms-description">{term_8.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_9.title}</h2>
                    <p className="terms-description">{term_9.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">
                        10. SERVICES MANAGEMENT
                    </h2>
                    <p className="terms-description">
                        We reserve the right to manage and monitor StudyBuddy to
                        ensure compliance with these Legal Terms. This includes
                        the right to:
                        <br />
                        <br />
                        <div className="terms-description-list">
                            <ul>
                                <li>
                                    Remove or edit any content that violates
                                    these terms or is otherwise objectionable.
                                </li>
                                <li>
                                    Suspend or terminate user accounts for
                                    violations or unauthorized activities.
                                </li>
                            </ul>
                        </div>
                        <br />
                    </p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">11. PRIVACY POLICY</h2>
                    <p className="terms-description">
                        At Studybuddy, we are committed to protecting your data
                        privacy and security. For detailed information on how we
                        handle your data, please review our Privacy Policy at{' '}
                        <a className="underline" href="https://studybuddy.gg/">
                            https://studybuddy.gg/privacy-policy.
                        </a>
                        By using our Services, you agree to the terms set forth
                        in our Privacy Policy, which forms part of these Legal
                        Terms.
                        <br />
                        <br />
                        Please be aware that our Services are hosted in the
                        United States. If you access our Services from outside
                        the U.S., with data protection laws that differ from
                        those in the United States, you are consenting to the
                        transfer of your data to the U.S. and to the processing
                        of your data according to U.S. laws by continuing to use
                        our Services.
                        <br />
                        <br />
                        We do not knowingly collect or solicit information from
                        individuals under the age of 13, nor do we target
                        marketing towards them. In line with the U.S. Children’s
                        Online Privacy Protection Act (COPPA), if we discover
                        that we have collected personal information from a child
                        under 13 without verifiable parental consent, we will
                        promptly delete such information from our records.
                    </p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_12.title}</h2>
                    <p className="terms-description">{term_12.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_13.title}</h2>
                    <p className="terms-description">{term_13.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_14.title}</h2>
                    <p className="terms-description">{term_14.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_15.title}</h2>

                    {term_15.descriptions.map((description) => {
                        return (
                            <p className="terms-description">
                                <p className="term-section-sub-title">
                                    {description.title}
                                </p>{' '}
                                {description.description}{' '}
                            </p>
                        );
                    })}
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_16.title}</h2>
                    <p className="terms-description">{term_16.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_17.title}</h2>
                    <p className="terms-description">{term_17.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_18.title}</h2>
                    <p className="terms-description">{term_18.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_19.title}</h2>
                    <p className="terms-description">{term_19.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_20.title}</h2>
                    <p className="terms-description">{term_20.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_21.title}</h2>
                    <p className="terms-description">{term_21.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_22.title}</h2>
                    <p className="terms-description">{term_22.description}</p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">23. CHANGE TO TERMS</h2>
                    <p className="terms-description">
                        Studybuddy2 LLC reserves the right to modify the Terms
                        under which https://studybuddy.gg/ is offered at its
                        sole discretion. The most recent version of the Terms
                        will replace all previous versions. We encourage you to
                        review the Terms periodically to stay informed about any
                        updates.
                    </p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">24. CONTACT US</h2>
                    <p className="terms-description">
                        To resolve a complaint regarding the Studybuddy Services
                        or to receive further information regarding use of the
                        Services, please contact us at:{' '}
                        <a href="https://studybuddy.gg/">
                            support@studybuddy.gg.
                        </a>
                    </p>
                </div>
                <div className="term-section-container">
                    <h2 className="term-section-title">{term_25.title}</h2>
                    <p className="terms-description">{term_25.description}</p>
                    {term_25.descriptions.map((description) => {
                        return (
                            <p className="terms-description">
                                <p className="term-section-sub-title">
                                    {description.title}
                                </p>{' '}
                                {description.description}{' '}
                            </p>
                        );
                    })}
                </div>
            </div>

            <Footer />
        </div>
    );
};
