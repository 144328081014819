import React, { FC } from 'react';
import { PopUpDescriptionCard } from './PopUpDescriptionCard';

import './ToolsPopup.styles.scss';

import { ReactComponent as CrossIcon } from '~assets/tools-popup-crossIcon.svg';
import { ReactComponent as CrossIconTurbo } from '~assets/tools-popup-crossIcon-turbo.svg';

// Title Images
import { ReactComponent as WriterDescriptionImgDesktop } from '~assets/writer-popup-desktop.svg';
import { ReactComponent as WriterDescriptionImgMobile } from '~assets/writer-popup-mobile.svg';
import { ReactComponent as AppDescriptionImgDesktop } from '~assets/app-popup-desktop.svg';
import { ReactComponent as AppDescriptionImgMobile } from '~assets/app-popup-mobile.svg';
import { ReactComponent as TurboDescriptionImgDesktop } from '~assets/turbo-popup-desktop.svg';
import { ReactComponent as TurboDescriptionImgMobile } from '~assets/turbo-popup-mobile.svg';

// description card images
// writer part
import { ReactComponent as WriterCreateDesktop } from '~assets/writer-create-desktop.svg';
import { ReactComponent as WriterCreateMobile } from '~assets/writer-create-mobile.svg';
import { ReactComponent as WriterDisguiseDesktop } from '~assets/writer-disguise-desktop.svg';
import { ReactComponent as WriterDisguiseMobile } from '~assets/writer-disguise-mobile.svg';
import { ReactComponent as WriterCiteDesktop } from '~assets/writer-cite-desktop.svg';
import { ReactComponent as WriterCiteMobile } from '~assets/writer-cite-mobile.svg';
import { ReactComponent as WriterRefineDesktop } from '~assets/writer-refine-desktop.svg';
import { ReactComponent as WriterRefineMobile } from '~assets/writer-refine-mobile.svg';
// app part
import { ReactComponent as AppCalculatorDesktop } from '~assets/app-calculator-desktop.svg';
import { ReactComponent as AppCalculatorMobile } from '~assets/app-calculator-mobile.svg';
import { ReactComponent as AppTutorDesktop } from '~assets/app-tutor-desktop.svg';
import { ReactComponent as AppTutorMobile } from '~assets/app-tutor-mobile.svg';
import { ReactComponent as AppWordDesktop } from '~assets/app-word-desktop.svg';
import { ReactComponent as AppWordMobile } from '~assets/app-word-mobile.svg';
import { ReactComponent as AppExplanationDesktop } from '~assets/app-explanation-desktop.svg';
import { ReactComponent as AppExplanationMobile } from '~assets/app-explanation-mobile.svg';

// turbo part
import { ReactComponent as TurboMoneybackDesktop } from '~assets/turbo-moneyback-desktop.svg';
import { ReactComponent as TurboMoneybackMobile } from '~assets/turbo-moneyback-mobile.svg';
import { ReactComponent as TurboLayerDesktop } from '~assets/turbo-layer-desktop.svg';
import { ReactComponent as TurboLayerMobile } from '~assets/turbo-layer-mobile.svg';
import { ReactComponent as Turbo3xDesktop } from '~assets/turbo-3x-desktop.svg';
import { ReactComponent as Turbo3xMobile } from '~assets/turbo-3x-mobile.svg';
import { ReactComponent as TurboGradedDesktop } from '~assets/turbo-graded-desktop.svg';
import { ReactComponent as TurboGradedMobile } from '~assets/turbo-graded-mobile.svg';

// gifs
import WriterCreateGif from '~assets/videos/writer-create.gif';
import WriterDisguiseGif from '~assets/videos/writer-disguise.gif';
import WriterCiteGif from '~assets/videos/writer-cite.gif';
import WriterRefineGif from '~assets/videos/writer-refine.gif';
import AppCalculatorGif from '~assets/videos/app-calculator.gif';
import AppAIGif from '~assets/videos/app-AI.gif';
import AppWordGif from '~assets/videos/app-word.gif';
import AppExplanationGif from '~assets/videos/app-explanation.gif';
import TurboMoneybackGif from '~assets/videos/turbo-moneyback.gif';
import TurboIncognitoGif from '~assets/videos/turbo-incognito.gif';
import Turbo3xGif from '~assets/videos/turbo-3x.gif';
import TurboGradedGif from '~assets/videos/turbo-graded.gif';

import { useScreenSize } from '~hooks';

type Props = {
    popupVariant?: string;
    open?: boolean;
    onClose: () => void;
};

export const ToolsPopup: FC<Props> = (props) => {
    const { open, onClose, popupVariant } = props;
    const { isMobile } = useScreenSize();

    const handleClose = () => {
        onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <div className="tools-popup">
            <div
                className={
                    popupVariant === 'turbo'
                        ? 'toolsPopup-content-turbo'
                        : 'toolsPopup-content'
                }
            >
                {/* header content */}
                <div className="toolsPopup-header">
                    <button onClick={handleClose} className="cross-icon-button">
                        {popupVariant === 'turbo' ? (
                            <CrossIconTurbo />
                        ) : (
                            <CrossIcon />
                        )}
                    </button>
                </div>
                {/* description content */}
                {popupVariant === 'turbo' ? (
                    <div
                        style={{ width: '100%' }}
                        className="tools-description"
                    >
                        {isMobile ? (
                            <TurboDescriptionImgMobile />
                        ) : (
                            <TurboDescriptionImgDesktop />
                        )}
                    </div>
                ) : (
                    <div className="tools-description">
                        {popupVariant === 'writer' &&
                            (isMobile ? (
                                <WriterDescriptionImgMobile />
                            ) : (
                                <WriterDescriptionImgDesktop />
                            ))}
                        {popupVariant === 'app' &&
                            (isMobile ? (
                                <AppDescriptionImgMobile />
                            ) : (
                                <AppDescriptionImgDesktop />
                            ))}
                    </div>
                )}

                {/* animated description content */}
                {popupVariant === 'writer' && (
                    <div className="tools-description-animated">
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={
                                isMobile
                                    ? WriterCreateMobile
                                    : WriterCreateDesktop
                            }
                            animatedImg={WriterCreateGif}
                        />
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #C97BF3',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 2.96px 0px #C97BF3',
                            }}
                            TitleImg={
                                isMobile
                                    ? WriterDisguiseMobile
                                    : WriterDisguiseDesktop
                            }
                            animatedImg={WriterDisguiseGif}
                        />
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #FC82BB',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 2.96px 0px #FC82BB',
                            }}
                            TitleImg={
                                isMobile ? WriterCiteMobile : WriterCiteDesktop
                            }
                            animatedImg={WriterCiteGif}
                        />

                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #8784FA',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 2.96px 0px #8784FA',
                            }}
                            TitleImg={
                                isMobile
                                    ? WriterRefineMobile
                                    : WriterRefineDesktop
                            }
                            animatedImg={WriterRefineGif}
                        />
                    </div>
                )}
                {popupVariant === 'app' && (
                    <div className="tools-description-animated">
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={
                                isMobile
                                    ? AppCalculatorMobile
                                    : AppCalculatorDesktop
                            }
                            animatedImg={AppCalculatorGif}
                        />
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={
                                isMobile ? AppTutorMobile : AppTutorDesktop
                            }
                            animatedImg={AppAIGif}
                        />
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={isMobile ? AppWordMobile : AppWordDesktop}
                            animatedImg={AppWordGif}
                        />

                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#FFF',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={
                                isMobile
                                    ? AppExplanationMobile
                                    : AppExplanationDesktop
                            }
                            animatedImg={AppExplanationGif}
                        />
                    </div>
                )}

                {popupVariant === 'turbo' && (
                    <div className="tools-description-animated">
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#0E153A',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={
                                isMobile
                                    ? TurboMoneybackMobile
                                    : TurboMoneybackDesktop
                            }
                            animatedImg={TurboMoneybackGif}
                        />
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#0E153A',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={
                                isMobile ? TurboLayerMobile : TurboLayerDesktop
                            }
                            animatedImg={TurboIncognitoGif}
                        />
                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#0E153A',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={isMobile ? Turbo3xMobile : Turbo3xDesktop}
                            animatedImg={Turbo3xGif}
                        />

                        <PopUpDescriptionCard
                            cardStyles={{
                                border: '1px #80B0FB',
                                backgroundColor: '#0E153A',
                                boxShadow: '0px 0px 2.96px 0px #83B3FB',
                            }}
                            TitleImg={
                                isMobile
                                    ? TurboGradedMobile
                                    : TurboGradedDesktop
                            }
                            animatedImg={TurboGradedGif}
                        />
                    </div>
                )}
            </div>
            <div className="background-modal" onClick={handleClose}></div>
        </div>
    );
};
