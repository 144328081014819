import React from 'react';
import { ReactComponent as DesktopFeature } from '~assets/features-desktop.svg';
import { ReactComponent as MobileFeature } from '~assets/features-mobile.svg';
import MobileFeatureIMG from '~assets/features-mobile.png';
import './NewFeature.styles.scss';

const NewFeature: React.FC = () => {
    return (
        <div id="feature-section" className="feature-section">
            <h2 className="feature-title">Undetectable By Teachers</h2>

            <div className="feature-content">
                <div className="feature-image-wrapper">
                    <div className="feature-image desktop">
                        <DesktopFeature />
                    </div>
                    <div className="feature-image-mobi mobile">
                        {/* <MobileFeature /> */}
                        <img src={MobileFeatureIMG} alt="feature" />
                    </div>
                    {/* <img
                        src={DesktopFeature}
                        alt="New Feature"
                        className="feature-image desktop"
                    />
                    <img
                        src={MobileFeature}
                        alt="New Feature Mobile"
                        className="feature-image-mobi mobile"
                    /> */}
                </div>
            </div>
        </div>
    );
};

export default NewFeature;
